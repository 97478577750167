import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import zhHans from 'vuetify/es5/locale/zh-Hans'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { zhHans },
        current: 'zhHans', //自动生成的中间带'-'是错的
    },
    icons: {
        iconfont: 'md',
    },
	breakpoint: {
		mobileBreakpoint: 'sm' // This is equivalent to a value of 960
	},
})