export function formatTime(t) {
    if (!t) return '';
    const time = new Date(t);
    const y = time.getFullYear();
    const m = time.getMonth() + 1;
    const d = time.getDate();
    const h = time.getHours();
    const mm = time.getMinutes();
    const s = time.getSeconds();
    const add0 = (m) => { return m < 10 ? '0' + m : m };
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

export function formatDate(t, separator='-') {
    if (!t) return '';
    const time = new Date(t);
    const y = time.getFullYear();
    const m = time.getMonth() + 1;
    const d = time.getDate();
    const add0 = (m) => { return m < 10 ? '0' + m : m };
    return y + separator + add0(m) + separator + add0(d);
}

export function formatSecond(result) {
    var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
    return h + ":" + m + ":" + s;
}

export const optstr = [
    ['胃肠镜', '胃镜', '肠镜'],
    ['无痛', '普通'],
    ['专家精查', '标准精查']
]
/*
export const colorsptz = [
    {tag: 't', color:'#FF6262',text:   'ZT',  durations:[45,30,60], global:false},
    {tag:'bt', color:'#CE92FE',text:   'BT',  durations:[45,30,60], global:false, hideDoctor:true},
    {tag: 'p', color:'#42A5F5',text: '视评',  durations:[10,15,30,40,60], global:true},
    {tag:'p2', color:'#FFB300',text: '院评',  durations:[10,15,30], global:false, hideDoctor:true, comments:['[内]','[内+麻]']},
    {tag: 'z', color:'#90D090',text: '解读',  durations:[10,15,30], global:true},
    {tag:'jtz',color:'#7986CB',text: 'JT-Z',  durations:[45,30,60], global:false},
    {tag: 'c', color:'#9E9D24',text: '门诊',  durations:[10,15,30,45,60], global:false},
    {tag:'bld',color:'#AD3477',text: '采血',  durations:[10,15], global:false},
]

export const duties = colorsptz.map(x => ({text:x.text, value:x.tag})).concat([{text:'麻醉', value:'mz'}]);
*/
export function calculateAge(birthDate, otherDate) {
    birthDate = new Date(birthDate);
    otherDate = otherDate ? new Date(otherDate) : new Date();
    let years = (otherDate.getFullYear() - birthDate.getFullYear());
    if (otherDate.getMonth() < birthDate.getMonth() ||
        otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
        years--;
    }
    return years;
}

export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export let nowOffsetHours = 0;
export function setNowOffsetHours(v) { nowOffsetHours = v };
export const todaystr = () => formatDate(Date.now() + nowOffsetHours * 3600 * 1000);
export const tomorrowstr = () => formatDate(Date.now() + (nowOffsetHours * 3600 + 86400) * 1000);
export const today = { begin: () => new Date(todaystr() + 'T00:00:00+08:00'), end: () => new Date(todaystr() + 'T23:59:59+08:00') };
export const tomorrow = { begin: () => new Date(tomorrowstr() + 'T00:00:00+08:00'), end: () => new Date(tomorrowstr() + 'T23:59:59+08:00') };

export function offsetDayTime(expectedDate, offsetDay, oc) {
    const ed = new Date(expectedDate + 'T00:00:00+08:00');
    const s = formatDate(ed.valueOf() + offsetDay * 86400 * 1000) + `T${oc}:00:00+08:00`;
    return Date.parse(s);
}

export function addMonths(numOfMonths, date = new Date()) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setMonth(dateCopy.getMonth() + numOfMonths);
    return dateCopy;
}

export const stages = [
    '选择健康顾问',
    '选择套餐',
    '填写健康问卷',
    '术前检查单',
    '上传检查结果',
    '预约评估',
    '评估日',
    '付款',
    '预约手术',
    '术前准备',
    '手术日',
    '术后管理',
    '病理报告解读'
];

import BMF from 'browser-md5-file';
import { promisify } from 'es6-promisify';
const bmf = new BMF;
export const md5p = promisify(bmf.md5.bind(bmf));

//引入远程js，加载完成后执行相应方法
export function loadJs(src) {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.type = "text/javascript";
        script.src = src;
        document.body.appendChild(script);
        script.onload = () => {
            resolve();
        }
        script.onerror = () => {
            reject();
        }
    })
}

export function checkIdCard(code) {
    if (code?.length !== 18) return false;
    code = code.split('');
    //∑(ai×Wi)(mod 11)
    //加权因子
    const factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
    //校验位
    const parity = [ '1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2' ];
    let sum = 0;
    for (let i = 0; i < 17; i++)
    {
        sum += code[i] * factor[i];
    }
    return (parity[sum % 11] === code[17]);
}

export const pathology_agencies = ['应急总医院','迪安','其他'];