import Home from '../views/Home2.vue'
import App from '../App2.vue'

const routes = [{
	path: '/login',
	name: 'Login',
	component: () =>
		import ('../views/Login.vue')
},
{
	path: '/',
	component: App,
	children: [{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: 'consultant',
			name: 'Consultant',
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () =>
				import ( /* webpackChunkName: "about" */ '../views/Consultant.vue')
		},
		{
			path: 'doctor2',
			name: 'Doctor',
			component: () =>
				import ('../views/Doctor2.vue')
		},
		{
			path: 'order2',
			name: 'Order',
			component: () =>
				import ('../views/Order2.vue')
		},
		{
			path: 'questionnaire2',
			name: 'Questionnaire',
			component: () =>
				import ('../views/Questionnaire2.vue')
		},
		{
			//                path: 'appointment/:phase',
			path: 'appointment2',
			name: 'Appointment',
			//                props: true,
			component: () =>
				import ('../views/Appointment2.vue')
		},
		{
			path: 'anesEval2',
			name: 'AnesEval',
			component: () =>
				import ('../views/AnesEval2.vue')
		},
		{
			path: 'deliversup2',
			name: 'DeliverSup',
			component: () =>
				import ('../views/DeliverSup2.vue')
		},
		{
			path: 'prepare2',
			name: 'Prepare',
			component: () =>
				import ('../views/Prepare2.vue')
		},
		{
			path: 'endoscopy2',
			name: 'Endoscopy',
			component: () =>
				import ('../views/Endoscopy2.vue')
		},
		{
			path: 'charge',
			name: 'Charge',
			component: () =>
				import ('../views/Charge.vue')
		},
		{
			path: 'pathology2',
			name: 'Pathology',
			component: () =>
				import ('../views/Pathology2.vue')
		},
		{
			path: 'subsequents',
			name: 'Subsequents',
			component: () =>
				import ('../views/Subsequents.vue')
		},
		{
			path: 'customer2',
			name: 'Customer',
			component: () =>
				import ('../views/Customer2.vue')
		},
		{
			path: 'note2',
			name: 'Note',
			component: () =>
				import ('../views/Note2.vue')
		},
		{
			path: 'videorec2',
			name: 'VideoRec',
			component: () =>
				import ('../views/VideoRec2.vue')
		},
		{
			path: 'feedback2',
			name: 'Feedback',
			component: () =>
				import ('../views/Feedback2.vue')
		},
		{
			path: 'report',
			name: 'Report',
			component: () =>
				import ('../views/Report.vue')
		},
		{
			path: 'userman',
			name: 'Userman',
			component: () =>
				import ('../views/User.vue')
		},
		{
			path: 'itemman',
			name: 'ItemMan',
			component: () =>
				import ('../views/ItemMan.vue')
		},
		{
			path: 'editinfo2',
			name: 'EditInfo',
			component: () =>
				import ('../views/EditInfo2.vue')
		},
		{
			path: 'sticker',
			name: 'Sticker',
			component: () =>
				import ('../views/Sticker.vue')
		},
		{
			path: 'coupon',
			name: 'Coupon',
			component: () =>
				import ('../views/Coupon.vue')
		},
		{
			path: 'redeem',
			name: 'Redeem',
			component: () =>
				import ('../views/Redeem.vue')
		},
		{
			path: 'clinic',
			name: 'Clinic',
			component: () =>
				import ('../views/Clinic.vue')
		},
		{
			path: 'ecg',
			name: 'ECG',
			component: () =>
				import ('../views/ECG.vue')
		},
		{
			path: 'preoper',
			name: 'Preoper',
			component: () =>
				import ('../views/Preoper.vue')
		},
		{
			path: 'huazhao',
			name: 'Huazhao',
			component: () =>
				import ('../views/Huazhao.vue')
		},
		{
			path: 'chargemisc',
			name: 'ChargeMisc',
			component: () =>
				import ('../views/ChargeMisc.vue')
		},
		{
			path: 'insurance',
			name: 'Insurance',
			component: () =>
				import ('../views/Insurance.vue')
		},
		{
			path: 'stat',
			name: 'Stat',
			component: () =>
				import ('../views/Stat.vue')
		},
		{
			path: 'statoper',
			name: 'StatOper',
			component: () =>
				import ('../views/StatOper.vue')
		},
		{
			path: 'statapnt',
			name: 'StatApnt',
			component: () =>
				import ('../views/StatApnt.vue')
		},
		{
			path: 'pathologytransfer',
			name: 'PathologyTransfer',
			component: () =>
				import ('../views/PathologyTransfer.vue')
		},
		{
			path: 'medicalrecordman',
			name: 'MedicalRecordMan',
			component: () =>
				import ('../views/MedicalRecordMan.vue')
		},
		{
			path: 'log2',
			name: 'Log',
			component: () =>
				import ('../views/Log2.vue')
		},
		{
			path: 'setting',
			name: 'Setting',
			component: () =>
				import ('../views/Setting.vue')
		}
	]
}]

export default routes