<template>
	<div style="height:100%">
		<v-toolbar dense flat color="white">
			<v-toolbar-title>{{ title }}</v-toolbar-title>
			<v-spacer/>
			<v-btn color="primary" small class="mr-10" @click.stop="dialog=true" v-if="caltype==='day' && permissions.includes('plan')">增加计划</v-btn>
			<v-btn fab text small @click="prev">{{"<"}}</v-btn>
			<v-btn outlined small class="mr-2 ml-2" @click.stop="returnClick">返回</v-btn>
			<v-btn fab text small @click="next">{{">"}}</v-btn>
		</v-toolbar>
		<v-sheet :style="{height: caltype === 'month' ? '765px' : 'calc( 100% - 90px )'}">
			<v-progress-linear indeterminate absolute :active="loading"></v-progress-linear>			
			<v-calendar
				v-model="focus"
				:now="focus+' 00:00:00'"
				color="primary"
				@change="updateRange"
				@click:date="viewDay"
				@moved="onMoved"
				:show-month-on-first="false"
				:type="caltype"
				:weekdays="[1,2,3,4,5,6,0]"
				:first-interval="13"
				:interval-count="29"
				:interval-minutes="30"
				:interval-width="50"
				:interval-height="130"
				:interval-format="interval => interval.time"
				locale="zh-cn"
				style="font-family:verdana,monospace"
				:hide-header="caltype === 'day'"
				ref="calendar">
				<template v-slot:day="{ present, past, date }">
					<v-layout column fill-height>
						<div v-for="v in plansView[date]" style="height:16px">
							<v-progress-linear rounded height="15"
								:color="v.color"
								:value="v.value"
								:active="v.active"
								@click="progClick(date)">
								<template v-slot="{ value }">
									<span class="caption" style="display:block;transform:scale(0.85);">{{ v.text }}</span>
								</template>
							</v-progress-linear>
						</div>
					</v-layout>
				</template>
				<template v-slot:day-body="{ date, timeToY, minutesToPixels }">
					<div v-for="(v, i) in eventsView[date]" class="my-event with-time text-center" @click.stop="editsch(i)"
						:style="{
							top: timeToY(v.starttimetext) + 'px',
							left: v.col * 205 + 'px',
							height: minutesToPixels(v.duration) + 'px',
							width: '200px',
							background: v.bkcolor
						}">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-if="v.deletable" v-bind="attrs" v-on="on" @click.stop="removePlan(v)" fab absolute width="20" height="20" style="top:4px;right:4px;">
									<v-icon small>close</v-icon>
								</v-btn>
							</template>
							<span>删除</span>
						</v-tooltip>
						<div :style="{color: v.ftcolor}">
							{{v.starttimetext}}
							{{v.doctor ? `- ${v.doctor}` : ''}}
							<v-tooltip bottom :disabled="!v.info2">
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on" v-html="v.info"></div>
								</template>
								<div v-html="v.info2"/>
							</v-tooltip>
						</div>
					</div>
				</template>
			</v-calendar>
		</v-sheet>
		<v-row>
			<v-container grid-list-md>
				<v-layout wrap class="pt-2" text-center>
					<v-flex style="min-width:40px;flex-basis:10%;flex-grow:0;font-size:13px;">图例</v-flex>
					<v-flex v-for="c in colorsptz" :key="c.text" style="min-width:80px;flex-basis:10%;flex-grow:0;">
						<v-card dark :color="c.color">
							<v-card-text class="pa-0" style="font-size:12px;">{{c.text}}</v-card-text>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-row>
		<v-dialog persistent v-model="dialog" width="500">
			<v-card>
				<v-card-title>增加计划{{curdate}}</v-card-title>
				<v-card-text>
					<v-form ref="formPlan" class="d-flex flex-wrap pa-2 my-plan" style="gap:8px">
						<v-select :items="colorsptz" item-value="tag" label="业务类型" v-model="newplan.tag" noDataText="" dense @change="onPlanTagChanged"></v-select>
						<v-select :items="doctors.filter(v=>v.duty.includes(newplan.tag)).map(v=>v.name)" v-model="newplan.doctor"
							noDataText="" dense label="医生" placeholder="选择医生" persistent-placeholder clearable></v-select>
						<v-select :items="queues" label="队列" v-model="newplan.queue" noDataText="" dense></v-select>
						<v-select :items="starttimes" label="开始时间" v-model="newplan.starttime" noDataText="" dense></v-select>
						<v-select :items="colorsptz.find(x => x.tag === newplan.tag).durations" label="单位时长" v-model="newplan.duration" noDataText="" dense></v-select>
						<v-text-field label="时段数" v-model.number="newplan.quota" type="number" :rules="rNum" :min="1" dense></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" @click.stop="addPlan" :loading="saveloading">确定</v-btn>
					<v-btn color="primary" outlined @click.stop="dialog=false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog persistent v-model="dialogsch" width="320">
			<v-card>
				<v-card-title>预约{{curdate}}</v-card-title>
				<v-card-text>
					<v-form ref="formsch">
						<v-chip class="mb-6" label text-color="white" :color="curAppointment.color">{{curAppointment.text}}</v-chip>
						<v-text-field label="开始时间" readonly :value="curAppointment.starttimetext" dense/>
						<slot name="details"></slot>
						<slot :name="'tag.'+curAppointment.tag"></slot>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||!lockcancel" @click.stop="savesch">预约</v-btn>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||lockcancel" @click.stop="deletesch">取消预约</v-btn>
					<v-btn color="primary" outlined @click.stop="dialogsch=false">返回</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog persistent v-model="dialogReserve" width="320">
			<v-card>
				<v-card-title>保留{{curdate}}</v-card-title>
				<v-card-text>
					<v-form ref="formReserve">
						<v-chip class="mb-6" label text-color="white" :color="curReserved.color">{{curReserved.text}}</v-chip>
						<v-text-field label="开始时间" readonly :value="curReserved.starttimetext" dense/>
						<v-text-field label="备注" v-model="curReserved.comment" dense/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||!lockcancel" @click.stop="saveReserve">保留</v-btn>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||lockcancel" @click.stop="deleteReserve">取消保留</v-btn>
					<v-btn color="primary" outlined @click.stop="dialogReserve=false">返回</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {formatDate, nowOffsetHours} from '../utils'
import {colorsptz} from '../workset3'
import store from '../store.js'

function hex2rgba(hex, opacity) {
	return "rgba(" + parseInt(hex.slice(1,3),16) + "," + parseInt(hex.slice(3,5),16) + "," + parseInt(hex.slice(5,7),16) + "," + opacity + ")";
}

function formatHM(t1) {
	let m = t1.getMinutes();
	const m1 = m < 10 ? '0' + m : m;
	return `${t1.getHours()}:${m1}`;
}

function tsarray2(sh,sm,durm,num) {
	let t = new Date(0);
	t.setHours(sh,sm);
	let r = [];
	const dt = durm * 60 * 1000;
	for (let i = 0; i < num; ++i) {
		const t1 = new Date(t.getTime() + i * dt);
		r.push({text:formatHM(t1), value:t1.getTime()});
	}
	return r;
}

export default {
	props: {
		permissions: {
			type: Array,
			default: () => []
		},
		showdialog: {
			type: Function,
			default: async(date, event) => false
		},
		currentdate: {
			type: String,
			default: formatDate(Date.now() + nowOffsetHours * 3600 * 1000)
		},
		orderid: {
			type: String,
			default: ''
		},
		highlight: {
			type: Function,
			default: function(v) { return this.orderid === v.orderid }
		},
		attach: {
			type: Object,
			default: () => ({})
		},
	},
	data() {
		return {
			caltype: 'month',
			curdate: '',
			title: '',
			starttimes: tsarray2(7, 0, 15, 53),
			colorsptz,
			queues: [1,2,3,4],
			doctors: [],
			newplan: {
				tag: 't',
				queue: 1,
				starttime: new Date(0).setHours(8,0),
				duration: 30,
				quota: 1,
				doctor: '',
				comment: '',
			},
			plans: {},
			plansView: {},
			eventsView: {},
			curReserved: {},
			curAppointment: {},
			plan: [],
			dialog: false,
			dialogsch: false,
			dialogReserve: false,
			rNum: [v => (/^[0-9]+$/g).test(v) || '不是有效的数字', v => parseInt(v) > 0 || '至少为1'],
			rText: [v => !!v || '不能为空'],
			saveloading: false,
			lockcancel: false,
			focus: this.currentdate,
			start_: '',
			end_: '',
			loading: false,
		}
	},
	mounted() {
		this.fetchDoctors();
	},
	watch: {
		currentdate(newitem, olditem) {
			this.focus = newitem;
		}
	},
	computed: {
		currentRegion() {
			return store.currentRegion;
		}
	},
	methods: {
		onPlanTagChanged() {
			this.newplan.duration = colorsptz.find(x => x.tag === this.newplan.tag).durations[0];
			this.newplan.doctor = '';
			this.newplan.comment = '';
		},
		getStarttime(item) {
			const t1 = new Date(item.starttime);
			return formatHM(t1);
		},
		getEndtime(item) {
			const d = item.duration || this.colorsptz.find(x => x.tag === item.tag).durations[0];
			const endtime = item.starttime + item.quota * d * 60 * 1000;
			const t1 = new Date(endtime);
			return formatHM(t1);
		},
		async addPlan() {
			if (!this.$refs.formPlan.validate()) return;
			this.saveloading = true;
			try {
				const res = await this.$callCloudFunc({
					funcname: 'addPlan',
					data: {
						date: this.curdate,
						plan: this.newplan,
					}
				});
				if (res.result.err) {
					this.$dialog.message.error(res.result.err);
				} else {
					this.$dialog.message.success('已保存');
					await this.fetchDetails(this.curdate);
					this.dialog = false;
				}
			} catch(err) {
				this.$dialog.message.error('保存失败');
				console.error(err);
			}
			this.saveloading = false;
		},
		async removePlan(plan) {
			const r = await this.$dialog.warning({
				text: '确定要删除此项？',
				title: '删除计划'
			});
			if (!r) return;
			this.saveloading = true;
			try {
				const res = await this.$callCloudFunc({
					funcname: 'removePlan',
					data: {
						date: this.curdate,
						plan,
					}
				});
				if (res.result.err) {
					this.$dialog.message.error(res.result.err);
				} else {
					this.$dialog.message.success('已删除');
					await this.fetchDetails(this.curdate);
					this.dialog = false;
				}
			} catch(err) {
				this.$dialog.message.error('删除失败');
				console.error(err);
			}
			this.saveloading = false;
		},
		/*
		async setDoctors(date) {
			const aps = this.appointments[date];
			if (!aps || aps.length === 0 || !this.eventsView[date]) return;
			const ods = [];
			for (let e of this.eventsView[date]) {
				const i = aps.findIndex(x => x.queue === e.queue && x.tag === e.tag && x.starttime === e.starttime);
				if (i > -1 && e.doctor) {
					ods.push({tag:e.tag, orderid:e.orderid, doctor:e.doctor});
				}
			}
			if (ods.length === 0) return;
			await this.$callCloudFunc({
				funcname: 'saveAppointmentsDoctor',
				data: {
					ods
				}
			});
		},
		*/
		makeView(date) {
			//月页
			const plan = this.plans[date];
			const pv = this.colorsptz.map(x => {
				const p = plan.filter(y => y.tag === x.tag);
				const quota_total = p.length;
				const used = p.filter(y => y.appointment || y.reserved).length;
				const value = quota_total ? used / quota_total * 100 : 0;
				const text = `${used} / ${quota_total}`;
				return { color:x.color, value, active:!!quota_total, text}
			})
			this.plansView[date] = pv;
		},
		makeDayView(date) {
			//日页
			const plan = this.plans[date];
			let r = [];
			let col = 0;
			this.colorsptz.forEach(x => {
				const byTag = plan.filter(y => y.tag === x.tag);
				this.queues.forEach(q => {
					const byQueue = byTag.filter(y => y.queue == q);
					if (byQueue.length) {
						const v = byQueue.map(e => ({...e, color:x.color, duration:e.duration, text:x.text, col}));
						v.forEach(u => {
							if (u.reserved) {
								u.bkcolor = `linear-gradient(${hex2rgba(x.color,0.4)} 50%, ${hex2rgba(x.color,0.2)} 50%) 0% 0% / 100% 17px`;
								u.ftcolor = 'black';
								u.info = u.reserved.comment;
							} else if (u.appointment) {
								u.bkcolor = hex2rgba(x.color, this.highlight(u.appointment) ? 1.0 : 0.6);
								u.ftcolor = 'white';
								u.info = u.appointment.info;
								u.info2 = u.appointment.info2;
								u.orderid = u.appointment.orderid;
							} else {
								u.bkcolor = hex2rgba(x.color,0.2);
								u.ftcolor = 'black';
								u.deletable = this.permissions.includes('plan')
							}
							r.push(u);
						})
						col++;
					}
				})
			})
			this.eventsView[date] = r;
		},
		viewDay({date}) {
			this.progClick(date);
		},
		async editsch(index) {
			const e = this.eventsView[this.curdate][index];
			if (this.permissions.includes('apnt')) {
				if (await this.showdialog(this.curdate, e)) {
					this.curAppointment = e;
					this.lockcancel = !e.info;
					this.dialogsch = true;
				}
			} else if (e.orderid) {
				this.$emit('click-order', e);
			} else if (this.permissions.includes('resv')) {
				this.curReserved = {...e};
				if (e.appointment) return;
				this.lockcancel = !e.reserved;
				this.curReserved.comment = e.reserved ? e.reserved.comment : '';
				this.dialogReserve = true;
			}
		},
		async saveReserve() {
			this.saveloading = true;
			try {
				const {tag, queue, starttime, comment} = this.curReserved;
				const res = await this.$callCloudFunc({
					funcname: 'reservePlace',
					data: {date: this.curdate, tag, queue, starttime, comment}
				});
				if (res.result.err) {
					this.$dialog.message.error(res.result.err);
				} else {
					this.$dialog.message.success('时段已保留');
					await this.fetchDetails(this.curdate);
					this.dialogReserve = false;
				}
			}
			catch(err) {
				this.$dialog.message.error('保留时段失败');
				console.error(err);
			}
			this.saveloading = false;
		},
		async deleteReserve() {
			this.saveloading = true;
			try {
				const {tag, queue, starttime} = this.curReserved;
				const res = await this.$callCloudFunc({
					funcname: 'cancelReserved',
					data: {date: this.curdate, tag, queue, starttime}
				});
				if (res.result.err) {
					this.$dialog.message.error(res.result.err);
				} else {
					this.$dialog.message.success('保留时段已取消');
					await this.fetchDetails(this.curdate);
					this.dialogReserve = false;
				}
			}
			catch(err) {
				this.$dialog.message.error('取消保留时段失败');
				console.error(err);
			}
			this.saveloading = false;
		},
		async savesch() {
			this.saveloading = true;
			try {
				const {tag, queue, starttime, doctor} = this.curAppointment;
				const res = await this.$callCloudFunc({
					funcname: 'makeAppointment',
					data: {date: this.curdate, tag, queue, starttime, doctor, orderid:this.orderid}
				});
				if (res.result.err) {
					this.$dialog.message.error(res.result.err);
				} else {
					this.$dialog.message.success('预约成功');
					await this.fetchDetails(this.curdate);
					this.dialogsch = false;
					this.$emit('aftermake');
				}
			}
			catch(err) {
				this.$dialog.message.error('预约失败');
				console.error(err);
			}
			this.saveloading = false;
		},
		async deletesch() {
			this.saveloading = true;
			try {
				const {tag, queue, starttime, orderid} = this.curAppointment;
				const res = await this.$callCloudFunc({
					funcname: 'cancelAppointment',
					data: {date: this.curdate, tag, queue, starttime, orderid}
				});
				if (res.result.err) {
					this.$dialog.message.error(res.result.err);
				} else {
					this.$dialog.message.success('取消预约成功');
					await this.fetchDetails(this.curdate);
					this.dialogsch = false;
					this.$emit('aftercancel');
				}
			}
			catch(err) {
				this.$dialog.message.error('取消预约失败');
				console.error(err);
			}
			this.saveloading = false;
		},
		onMoved({date}) {
		},
		findFirstAvailable(date, tag, queue) {
			const ev = this.eventsView[date];
			if (!ev) return null;
			const r = ev.filter(x => x.tag === tag && x.queue === queue && (x.info === null || x.info === undefined)).sort((a, b) => a.starttime - b.starttime);
			return r[0];
		},
		returnClick() {
			if (this.caltype !== 'month') {
				this.caltype = "month";
			}
		},
		prev () {
			this.$refs.calendar.prev();
			this.$nextTick(() => { this.curdate = this.$refs.calendar.value });
		},
		next () {
			this.$refs.calendar.next();
			this.$nextTick(() => { this.curdate = this.$refs.calendar.value });
		},
		updateRange({start, end}) {
			let options = {year:"numeric", month:"short"};
			if (start.date === end.date) {
				options = {...options, day:"numeric", weekday: "long"};
			}
			this.title = new Intl.DateTimeFormat('zh-cn', options).format(new Date(start.date)); 
			this.start_ = start.date;
			this.end_ = end.date;
			this.fetchdata(start.date, end.date);
		},
		async progClick(e) {
			this.focus = e;
			this.curdate = e;
			await this.fetchDetails(e);
			this.caltype = 'day';
		},
		async fetchDetails(date) {
			this.loading = true;
			try {
				const res = await this.$callCloudFunc({
					funcname: 'getDayDetails',
					data: {
						date,
					}
				});
				this.plans[date] = res.result;
				this.makeDayView(date);
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
		async fetchdata(startdate, enddate) {
			this.loading = true;
			try {
				const res = await this.$callCloudFunc({
					funcname: 'getCalendar',
					data: {
						startdate,
						enddate,
					}
				});
				this.plans = {};
				for(let d in res.result) {
					this.plans[d] = res.result[d].plans;
					this.makeView(d);
				}
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
		async fetchDoctors() {
			try {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const res = await db.collection('wp3doctor')
					.where({disabled:_.neq(true)})
					.orderBy('rank','desc')
					.field({name:true, duty:true})
					.limit(100)
					.get();
				this.doctors = res.data;
			} catch(err) {
				console.error(err);
			}
		},
	}
}
</script>

<style>
.my-event {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-radius: 3px;
	color: #ffffff;
	border: 1px solid #ffffff;
	font-size: 12px;
	padding: 3px;
	cursor: pointer;
	margin-bottom: 1px;
	left: 4px;
	margin-right: 8px;
	position: relative;
}
.my-event.with-time {
	position: absolute;
	right: 4px;
	margin-right: 0px;
}
.my-plan > * {
	flex-basis:30%;
}
</style>
