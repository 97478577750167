import {formatTime} from './utils'

export const colorsptz = [
    {tag: 't', color:'#FF6262',text: '专家镜检',  durations:[30,45,60]},
    {tag:'bt', color:'#CE92FE',text: '标准镜检',  durations:[30,45,60]},
    {tag: 'p', color:'#42A5F5',text: '检前评估',  durations:[10,15,30,45,60]},
    {tag: 'z', color:'#90D090',text: '病理解读',  durations:[10,15,30,45,60]},
    {tag: 'c', color:'#FFB300',text: '消化门诊',  durations:[10,15,30,45,60]},
]

export const duties = colorsptz.map(x => ({text:x.text, value:x.tag, color:x.color})).concat([{text:'内部科室', value:'nb', color:'#9E9D24'}]);

export const mritems = [
	'主诉',
	'现病史',
	'既往史',
	'家族史',
	'手术史',
	'过敏史',
	'体格检查',
	'辅助检查',
	'诊断',
	'处置',
	'嘱托',
];

export const stgExamItems = ["未开检查单", "已开检查单", "已收讫报告", "跳过此步"];
export const stgEvalItems = ["未评估", "评估通过", "评估未通过", "跳过此步"];
export const stgOprtItems = ["未进行", "镜检完成 - 无病理", "镜检完成 - 有病理", "放弃检查"];
export const stgIntpItems = ["未解读", "解读完成", "放弃解读"];
export const stgClncItems = ["未完诊", "已完诊", "已取消"];

export function buildSteps(od) {
	let r = [];
	if (od.payment?.amount === null || od.payment?.amount === undefined) {
		r.push({text:'支付', state: 0, hint:'未开始'})
	} else if (od.payment?.actual === null || od.payment?.actual === undefined) {
		r.push({text:'支付', state: 1, hint:'未支付'})
	} else {
		r.push({text:'支付', state: 2, hint:'已支付'})
	}
	if (od.stages?.['检前评估'] === "评估通过") {
		r.push({text:'检前', state: 2, hint:'通过'})
	} else if (od.stages?.['检前评估'] === '评估未通过') {
		r.push({text:'检前', state: 3, hint:'未通过'})
	} else if (od.stages?.['检前评估'] === '跳过此步') {
		r.push({text:'检前', state: 4, hint:'跳过'})
	} else if (['已开检查单', '已收讫报告','跳过此步'].includes(od.stages?.['检前检查'])) {
		r.push({text:'检前', state: 1, hint:'等待检前'})
	} else if (od.apntEvalDate) {
		r.push({text:'检前', state:5, hint:`已预约 ${formatTime(od.apntEvalDate)}`})
	} else {
		r.push({text:'检前', state: 0, hint:'未开始'})
	}
	if (["镜检完成 - 无病理","镜检完成 - 有病理"].includes(od.stages?.['内镜检查'])) {
		r.push({text:'镜检', state: 2, hint:'完成'})
	} else if (od.stages?.['内镜检查'] === '放弃检查') {
		r.push({text:'镜检', state: 3, hint:'放弃'})
	} else if (od.apntOprtDate) {
		r.push({text:'镜检', state:5, hint:`已预约 ${formatTime(od.apntOprtDate)}`})
	} else {
		r.push({text:'镜检', state: 0, hint:'未开始'})
	}
	if (od.stages?.['病理解读'] === '解读完成') {
		r.push({text:'解读', state: 2, hint:'完成'})
	} else if (od.stages?.['病理解读'] === '放弃解读') {
		r.push({text:'解读', state: 4, hint:'放弃'})
	} else if (od.apntIntpDate) {
		r.push({text:'解读', state:5, hint:`已预约 ${formatTime(od.apntIntpDate)}`})
	} else {
		r.push({text:'解读', state: 0, hint:'未开始'})
	}
	if (od.completed) {
		r.push({text:'完成', state: 2, hint:'全流程已完成'});
	} else {
		r.push({text:'完成', state: 0, hint:'未完成'});
	}
	return r;
}

export const patientColors = [
	{text:'蓝色', color:'light-blue lighten-2'},
	{text:'橙色', color:'amber lighten-1'},
	{text:'红色', color:'red lighten-1'},
];

export async function setPatientColor(db, res) {
	const _ = db.command;
	const pids = res.data.map(x => x.pid);
	const r = await db.collection('patient').where({_id:_.in(pids)}).field({notes:true}).get();
	res.data.forEach(x => {
		const notes = r.data.find(y => y._id === x.pid)?.notes || [];
		if (notes.length > 0) {
			const s = notes[notes.length - 1].color;
			x.color = patientColors.find(x => x.text === s)?.color || '';
		}
	});
}