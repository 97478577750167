import Home from '../views/Home3.vue'
import App from '../App3.vue'

const routes = [{
	path: '/login',
	name: 'Login',
	component: () =>
		import ('../views/Login.vue')
},
{
	path: '/',
	component: App,
	children: [{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: 'order',
			name: 'Order',
			component: () =>
				import ('../views/Order3.vue')
		},
		{
			path: 'payment',
			name: 'Payment',
			component: () =>
				import ('../views/Payment3.vue')
		},
		{
			//                path: 'appointment/:phase',
			path: 'appointment',
			name: 'Appointment',
			//                props: true,
			component: () =>
				import ('../views/Appointment3.vue')
		},
		{
			path: 'preexam',
			name: 'PreExam',
			component: () =>
				import ('../views/PreExam3.vue')
		},
		{
			path: 'endoscopy',
			name: 'Endoscopy',
			component: () =>
				import ('../views/Endoscopy3.vue')
		},
		{
			path: 'charge',
			name: 'Charge',
			component: () =>
				import ('../views/Charge3.vue')
		},
		{
			path: 'pathology',
			name: 'Pathology',
			component: () =>
				import ('../views/Pathology3.vue')
		},
		{
			path: 'report',
			name: 'Report',
			component: () =>
				import ('../views/Report3.vue')
		},
		{
			path: 'clinic',
			name: 'Clinic',
			component: () =>
				import ('../views/Clinic3.vue')
		},
		{
			path: 'userman',
			name: 'Userman',
			component: () =>
				import ('../views/User3.vue')
		},
		{
			path: 'doctor',
			name: 'Doctor',
			component: () =>
				import ('../views/Doctor3.vue')
		},
		{
			path: 'itemman',
			name: 'ItemMan',
			component: () =>
				import ('../views/ItemMan3.vue')
		},
		{
			path: 'chargetmpl',
			name: 'Chargetmpl',
			component: () =>
				import ('../views/ChargeTmpl3.vue')
		},
		{
			path: 'config',
			name: 'Config',
			component: () =>
				import ('../views/Config3.vue')
		},
		{
			path: 'log',
			name: 'Log',
			component: () =>
				import ('../views/Log3.vue')
		},
	]
}]

export default routes