<template>
	<div v-if="authed">
		<Calendar2 :permissions="permissions" :showdialog="()=>true" :highlight="()=>true" @click-order="onClickOrder">
		</Calendar2>
	</div>
    <div v-else>欢迎</div>
</template>

<script>
// @ is an alias to /src
import Calendar2 from '@/components/Calendar2.vue'

export default {
	name: 'Home',
	data() {
		return {
            authed: true,
			permissions: [],
		}
	},
	mounted() {
		this.permissions = [];
		if (this.$hasPrivilege('运营计划')) this.permissions.push('plan');
		if (this.$hasPrivilege('保留时段')) this.permissions.push('resv');
	},
	methods: {
		onClickOrder(e) {
			if (e.tag === 'p' || e.tag === 'p2') {
				if (this.$hasPrivilege(['术前评估','消化内科','麻醉科'])) {
					this.$router.push({name:'AnesEval', params: { orderid:e.orderid }});
					return;
				}
			}
			this.$router.push({name:'Customer', params: { orderid:e.orderid }});
		}
	},
	components: {
		Calendar2
	}
}
</script>
