<template>
	<div style="height:100%">
		<v-toolbar dense flat color="white">
			<v-toolbar-title>{{ title }}</v-toolbar-title>
			<v-spacer/>
			<v-btn fab text small @click="prev">{{"<"}}</v-btn>
			<v-btn outlined small class="mr-2 ml-2" @click="returnClick">返回</v-btn>
			<v-btn fab text small @click="next">{{">"}}</v-btn>
		</v-toolbar>
		<v-sheet :style="{height: caltype === 'month' ? '954px' : 'calc( 100% - 106px )'}">
			<v-progress-linear indeterminate absolute :active="loading"></v-progress-linear>			
			<v-calendar
				v-model="focus"
				:now="focus+' 00:00:00'"
				color="primary"
				@change="updateRange"
				@click:date="viewDay"
				@moved="onMoved"
				:show-month-on-first="false"
				:type="caltype"
				:weekdays="[1,2,3,4,5,6,0]"
				:first-interval="13"
				:interval-count="29"
				:interval-minutes="30"
				:interval-width="50"
				:interval-height="130"
				:interval-format="interval => interval.time"
				ref="calendar">
				<template v-slot:day="{ present, past, date }">
					<v-layout column fill-height>
						<div v-for="v in plansView[date]" style="height:14px">
							<v-progress-linear rounded height="13"
								:color="v.color"
								:value="v.value"
								:active="v.active"
								@click="progClick(date)">
								<template v-slot="{ value }">
									<span class="caption" style="display:block;transform:scale(0.85);">{{ v.text }}</span>
								</template>
							</v-progress-linear>
						</div>
					</v-layout>
				</template>
				<template v-slot:day-body="{ date, timeToY, minutesToPixels }">
					<div v-for="(v, i) in eventsView[date]" class="my-event with-time text-center" @click.stop="editsch(i)"
						:style="{
							top: timeToY(v.starttimestr) + 'px',
							left: v.col * 195 + 'px',
							height: minutesToPixels(v.duration) + 'px',
							width: '190px',
							background: v.bkcolor
						}">
						<div :style="{color: v.ftcolor}">
							{{v.starttimestr}}
							{{v.region ? `- ${v.region}` : ''}}
							{{v.doctorT ? `- ${v.doctorT}` : ''}}
							{{v.comment ? `- ${v.comment}` : ''}}
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on" v-html="v.info"></div>
								</template>
								<div v-html="v.info2"/>
							</v-tooltip>
						</div>
					</div>
				</template>
			</v-calendar>
		</v-sheet>
		<v-row>
			<v-col cols="12">
				<v-container grid-list-md text-center>
					<v-layout wrap>
						<v-flex style="min-width:40px;flex-basis:10%;flex-grow:0;font-size:12px;">图例</v-flex>
						<v-flex v-for="c in colorsptz" :key="c.text" style="min-width:40px;flex-basis:10%;flex-grow:0;">
							<v-card dark :color="c.color">
								<v-card-text class="pa-0" style="font-size:12px;">{{c.text}}</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-container>
			</v-col>
		</v-row>
		<v-dialog persistent v-model="dialog" width="760">
			<v-card>
				<v-card-title>运营计划[{{currentRegion}}]{{curdate}}</v-card-title>
				<v-card-text>
					<v-form ref="formPlan">
						<v-container>
							<v-row id="planrow">
								<v-col>
									<v-select :items="colorsptz" item-value="tag" label="业务类型" v-model="newplan.tag" noDataText="" dense @change="onPlanTagChanged"></v-select>
								</v-col>
								<v-col cols="1">
									<v-select :items="queues" label="队列" v-model="newplan.queue" noDataText="" dense></v-select>
								</v-col>
								<v-col>
									<v-select :items="starttimes" label="开始时间" v-model="newplan.starttime" noDataText="" dense></v-select>
								</v-col>
								<v-col>
									<v-select :items="colorsptz.find(x => x.tag === newplan.tag).durations" label="时长" v-model="newplan.duration" noDataText="" dense></v-select>
								</v-col>
								<v-col cols="1">
									<v-text-field label="时段数" v-model.number="newplan.quota" type="number" :rules="rNum" :error-messages="qterr" dense></v-text-field>
								</v-col>
								<v-col>
									<v-select :items="doctors.filter(v=>v.duty.includes(newplan.tag))" item-text="name" item-value="name" label="医生" v-model="newplan.doctor" noDataText="" dense></v-select>
								</v-col>
								<v-col cols="2">
									<v-select :items="colorsptz.find(x => x.tag === newplan.tag).comments" label="备注" v-model="newplan.comment" noDataText="" dense/>
								</v-col>
								<v-col cols="1">
									<v-btn fab dark x-small color="primary" @click.stop="addPlan">
										<v-icon dark small>mdi-plus</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
					<v-data-table :headers="planHeaders" :items="planItems" dense height="240" hide-default-footer no-data-text="无计划" :items-per-page="-1"
						style="width:100%;border-top:1px solid #777;border-bottom:1px solid #777;">
						<template v-slot:item.tag="{ item }">
							{{ colorsptz.find(x => x.tag === item.tag).text }}
						</template>
						<template v-slot:item.starttime="{ item }">
							{{ getStarttime(item) }}
						</template>
						<template v-slot:item.endtime="{ item }">
							{{ getEndtime(item) }}
						</template>
						<template v-slot:item.remove="{ item }">
							<v-icon class="mr-2" @click.stop="removePlan(item)">remove_circle_outline</v-icon>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" @click.stop="savePlan" :loading="saveloading">保存</v-btn>
					<v-btn color="primary" outlined @click.stop="dialog=false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog persistent v-model="dialogsch" width="320">
			<v-card>
				<v-card-title>预约[{{currentRegion}}]{{curdate}}</v-card-title>
				<v-card-text>
					<v-form ref="formsch">
						<v-container grid-list-xl>
							<v-layout>
								<v-flex>
									<v-chip class="mb-6" label text-color="white" :color="curAppointment.color">{{curAppointment.text}}</v-chip>
									<v-text-field label="开始时间" readonly :value="curAppointment.starttimestr" dense/>
									<slot name="details"></slot>
									<slot :name="'tag.'+curAppointment.tag"></slot>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||!lockcancel" @click.stop="savesch">预约</v-btn>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||lockcancel" @click.stop="deletesch">取消预约</v-btn>
					<v-btn color="primary" outlined @click.stop="dialogsch=false">返回</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog persistent v-model="dialogReserve" width="320">
			<v-card>
				<v-card-title>保留[{{currentRegion}}]{{curdate}}</v-card-title>
				<v-card-text>
					<v-form ref="formReserve">
						<v-container grid-list-xl>
							<v-layout>
								<v-flex>
									<v-chip class="mb-6" label text-color="white" :color="curReserved.color">{{curReserved.text}}</v-chip>
									<v-text-field label="开始时间" readonly :value="curReserved.starttimestr" dense/>
									<v-text-field label="备注" v-model="curReserved.comment" dense/>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||!lockcancel" @click.stop="saveReserve">保留</v-btn>
					<v-btn color="primary" :loading="saveloading" :disabled="saveloading||lockcancel" @click.stop="deleteReserve">取消保留</v-btn>
					<v-btn color="primary" outlined @click.stop="dialogReserve=false">返回</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	    <v-snackbar color="error" v-model="snackbar">
			{{errmsg}}
			<template v-slot:action="{ attrs }">
				<v-btn outlined small v-bind="attrs" @click="snackbar = false">关闭</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import {formatDate, nowOffsetHours} from '../utils'
import {colorsptz} from '../workset'
import store from '../store.js'

function regionStr() {
	return store.currentRegion === '北京丰联' ? null : store.currentRegion
}

function hex2rgba(hex, opacity) {
	return "rgba(" + parseInt(hex.slice(1,3),16) + "," + parseInt(hex.slice(3,5),16) + "," + parseInt(hex.slice(5,7),16) + "," + opacity + ")";
}

function formatHM(t1) {
	let m = t1.getMinutes();
	const m1 = m < 10 ? '0' + m : m;
	return `${t1.getHours()}:${m1}`;
}

function tsarray(sh,sm,durm,num) {
	let t = new Date(0);
	t.setHours(sh,sm);
	let r = [];
	const dt = durm * 60 * 1000;
	for (let i = 0; i < num; ++i) {
		const t1 = new Date(t.getTime() + i * dt);
		r.push(formatHM(t1));
	}
	return r;
}

function tsarray2(sh,sm,durm,num) {
	let t = new Date(0);
	t.setHours(sh,sm);
	let r = [];
	const dt = durm * 60 * 1000;
	for (let i = 0; i < num; ++i) {
		const t1 = new Date(t.getTime() + i * dt);
		r.push({text:formatHM(t1), value:t1.getTime()});
	}
	return r;
}

export default {
	props: {
		permissions: {
			type: Array,
			default: () => []
		},
		showdialog: {
			type: Function,
			default: (date, event) => false
		},
		currentdate: {
			type: String,
			default: formatDate(Date.now() + nowOffsetHours * 3600 * 1000)
		},
		orderid: {
			type: String,
			default: ''
		},
		highlight: {
			type: Function,
			default: function(v) { return this.orderid === v.orderid }
		},
		attach: {
			type: Object,
			default: () => ({})
		},
	},
	data() {
		return {
			caltype: 'month',
			curdate: '',
			title: '',
			planHeaders: [
				{text:'业务', value:'tag', width:80},
				{text:'队列', value:'queue', width:80},
				{text:'开始', value:'starttime', width:80},
				{text:'结束', value:'endtime', width:80},
				{text:'时段', value:'quota', width:80},
				{text:'医生', value:'doctor', width:100},
				{text:'备注', value:'comment', width:100},
				{text:'删除', value:'remove', width:80}
			],
			planItems: [],
			starttimes: tsarray2(7, 0, 15, 53),
			colorsptz,
			queues: [1,2,3,4],
			doctors: [],
			newplan: {
				tag: 't',
				queue: 1,
				starttime: new Date(0).setHours(7,0),
				duration: 45,
				quota: 1,
				doctor: '',
				comment: '',
			},
			qterr: '',
			plans: {},
			plansView: {},
			eventsView: {},
			reserved: {},
			curReserved: {},
			appointments: {},
			curAppointment: {},

			plan: [],
			dialog: false,
			dialogsch: false,
			dialogReserve: false,
			editschindex: -1,
			curptz:0,
			rNum: [v => (/^[0-9]+$/g).test(v) || '不是有效的数字', v => parseInt(v) > 0 || '至少为1'],
			rText: [v => !!v || '不能为空'],
			saveloading: false,
			lockcancel: false,
			curEvent: null,
			curComment: '',
			starttime:'',
			focus: this.currentdate,
			snackbar: false,
			errmsg: '',
			start_: '',
			end_: '',
			loading: false
		}
	},
	mounted() {
		this.fetchDoctors();
		this.$watch(()=>store.currentRegion, function (newVal, oldVal) {
			this.fetchDoctors();
			this.fetchdata(this.start_, this.end_).then(()=>{
				if (this.caltype === 'day') {
					this.fetchDetails(this.curdate);
				}
			})
		});
	},
	watch: {
		currentdate(newitem, olditem) {
			this.focus = newitem;
		}
	},
	computed: {
		currentRegion() {
			return store.currentRegion;
		}
	},
	methods: {
		onPlanTagChanged() {
			this.newplan.duration = colorsptz.find(x => x.tag === this.newplan.tag).durations[0];
			this.newplan.doctor = '';
			this.newplan.comment = '';
		},
		getStarttime(item) {
			const t1 = new Date(item.starttime);
			return formatHM(t1);
		},
		getEndtime(item) {
			const d = item.duration || this.colorsptz.find(x => x.tag === item.tag).durations[0];
			const endtime = item.starttime + item.quota * d * 60 * 1000;
			const t1 = new Date(endtime);
			return formatHM(t1);
		},
		addPlan() {
			this.qterr = '';
			if (!this.$refs.formPlan.validate()) return;
			const { tag, queue, starttime, duration, quota } = this.newplan;
			const queues = this.planItems.filter(x => x.tag === tag && x.queue === queue);
			if (queues.length > 0) {
				const endtime = starttime + quota * duration * 60 * 1000 - 1;
				for(const q of queues) {
					const qendtime = q.starttime + q.quota * q.duration * 60 * 1000 - 1;
					if (!(q.starttime > endtime || qendtime < starttime)) {
						this.qterr = '时间段有重叠';
						return;
					}
				}
			}
			this.planItems.push({...this.newplan});
		},
		removePlan(item) {
			this.planItems.splice(this.planItems.indexOf(item), 1);
		},
		async savePlan() {
			this.saveloading = true;
			try {
				await this.$callCloudFunc({
					funcname: 'savePlan',
					data: {
						date: this.curdate,
						region: store.currentRegion,
						plan: this.planItems
					}
				});
				this.plans[this.curdate] = [...this.planItems];
				this.makeView(this.curdate);
				this.makeDayView(this.curdate);
				await this.setDoctors(this.curdate);
				this.dialog = false;
			} catch(err) {
				console.error(err);
			}
			this.saveloading = false;
		},
		async setDoctors(date) {
			const aps = this.appointments[date];
			if (!aps || aps.length === 0 || !this.eventsView[date]) return;
			const ods = [];
			for (let e of this.eventsView[date]) {
				const i = aps.findIndex(x => x.queue === e.queue && x.tag === e.tag && x.starttime === e.starttime);
				if (i > -1 && e.doctor) {
					ods.push({tag:e.tag, orderid:e.orderid, doctor:e.doctor});
				}
			}
			if (ods.length === 0) return;
			await this.$callCloudFunc({
				funcname: 'saveAppointmentsDoctor',
				data: {
					ods
				}
			});
		},
		makeView(date) {
			const plan = this.plans[date];
			//月页
			const pv = this.colorsptz.map(x => {
				const quota_total = plan.filter(y => y.tag === x.tag).reduce((pre, cur) => pre + cur.quota, 0);
				const apnt_total =  (this.appointments[date] || []).filter(y => y.tag === x.tag).length;
				const rsvr_total =  (this.reserved[date] || []).filter(y => y.tag === x.tag).length;
				const used = apnt_total + rsvr_total;
				const value = quota_total ? used / quota_total * 100 : 0;
				const text = `${used} ~ ${quota_total - used}`;
				return { color:x.color, value, active:!!quota_total, text}
			})
			this.plansView[date] = pv;
		},
		makeDayView(date) {
			const plan = this.plans[date];
			//日页-计划
			let r = [];
			let col = 0;
			this.colorsptz.forEach(x => {
				const byTag = plan.filter(y => y.tag === x.tag);
				this.queues.forEach(q => {
					const byQueue = byTag.filter(y => y.queue == q);
					if (byQueue.length) {
//						const v = byQueue.map(e => ({...e, color:x.color, bkcolor:hex2rgba(x.color,0.2), ftcolor:'black', duration:x.duration, text:x.text, col}));
						const v = byQueue.map(e => ({...e, color:x.color, bkcolor:hex2rgba(x.color,0.2), ftcolor:'black', duration:e.duration || x.durations[0], text:x.text, col}));
						v.forEach(u => {
							for (let i = 0; i < u.quota; ++i) {
								let uu = {...u};
								uu.starttime = uu.starttime + i * uu.duration * 60 * 1000;
								uu.starttimestr = formatHM(new Date(uu.starttime));
								if (!x.hideDoctor) uu.doctorT = uu.doctor;	//2023-10-20[张捷] 不显示BT医生
								r.push(uu);
							}
						})
						col++;
					}
				})
			})
			//保留时段
			if (this.reserved[date]) {
				for (let rv of this.reserved[date]) {
					const e = r.find(x => x.tag === rv.tag && x.queue === rv.queue && x.starttime === rv.starttime);
					if (e) {
						e.bkcolor = `linear-gradient(${hex2rgba(e.color,0.4)} 50%, ${hex2rgba(e.color,0.2)} 50%) 0% 0% / 100% 17px`;
						e.ftcolor = 'black';
						e.info = rv.comment;
					}
				}
			}
			//预约
			if (this.appointments[date]) {
				for (let v of this.appointments[date]) {
					const e = r.find(x => x.tag === v.tag && x.queue === v.queue && x.starttime === v.starttime);
					if (e) {
						e.bkcolor = hex2rgba(e.color, this.highlight(v) ? 1.0 : 0.6);
						e.ftcolor = 'white';
						e.info = v.info;
						e.info2 = v.info2;
						e.orderid = v.orderid;
					}
				}
			}
			this.eventsView[date] = r;
		},
		viewDay({date}) {
			if (!this.permissions.includes('plan')) return;
			this.curdate = date;
			this.planItems = this.plans[date] || [];
			this.dialog = true;
		},
		editsch(index) {
			const e = this.eventsView[this.curdate][index];
			if (this.permissions.includes(e.tag)) {
				if (this.showdialog(this.curdate, e)) {
					this.curAppointment = e;
					this.lockcancel = !e.info;
					this.dialogsch = true;
				}
			} else if (e.orderid) {
				this.$emit('click-order', e);
			} else if (this.permissions.includes('resv')) {
				this.curReserved = {...e, comment:''};
				const rd = this.reserved[this.curdate];
				if (rd) {
					const r = rd.find(x => x.tag === e.tag && x.queue === e.queue && x.starttime === e.starttime);
					if (r) {
						this.curReserved.comment = r.comment;
						this.lockcancel = false;
						this.dialogReserve = true;
						return;
					}
				}
				this.lockcancel = true;
				this.dialogReserve = true;
			}
		},
		async saveReserve() {
			this.saveloading = true;
			if (!this.reserved[this.curdate]) this.reserved[this.curdate] = [];
			try {
				const {tag, queue, starttime, comment} = this.curReserved;
				const r = {tag, queue, starttime, comment};
				await this.$callCloudFunc({
					funcname: 'reservePlace',
					data: {...r, date: this.curdate, region:store.currentRegion}
				});
				this.reserved[this.curdate].push(r);
				this.makeDayView(this.curdate);
				this.dialogReserve = false;
			}
			catch(err) {
				console.error(err);
			}
			this.saveloading = false;
		},
		async deleteReserve() {
			this.saveloading = true;
			try {
				const {tag, queue, starttime} = this.curReserved;
				const r = {tag, queue, starttime};
				await this.$callCloudFunc({
					funcname: 'cancelReserved',
					data: {...r, date: this.curdate, region:store.currentRegion}
				});
				const i = this.reserved[this.curdate].findIndex(x => x.tag === tag && x.queue === queue && x.starttime === starttime);
				this.reserved[this.curdate].splice(i, 1);
				this.makeDayView(this.curdate);
				this.dialogReserve = false;
			}
			catch(err) {
				console.error(err);
			}
			this.saveloading = false;
		},
		onMoved({date}) {
			console.log(date + ' moved');
		},
		findFirstAvailable(date, tag, queue) {
			const ev = this.eventsView[date];
			if (!ev) return null;
			const r = ev.filter(x => x.tag === tag && x.queue === queue && (x.info === null || x.info === undefined)).sort((a, b) => a.starttime - b.starttime);
			return r[0];
		},
		async savesch() {
			this.saveloading = true;
			if (!this.appointments[this.curdate]) this.appointments[this.curdate] = [];
			try {
				const {tag, queue, starttime, doctor} = this.curAppointment;
				const r = {tag, queue, starttime, doctor};
				if (this.attach[tag]) {
					r.attach = this.attach[tag]();
				}
				const res = await this.$callCloudFunc({
					funcname: 'makeAppointment',
					data: {...r, date: this.curdate, region:store.currentRegion, orderid:this.orderid}
				});
				if (res.result.ok) {
					this.appointments[this.curdate].push({...r, orderid:this.orderid, info:res.result.info});
					this.makeDayView(this.curdate);
					this.$emit('aftermake');
					this.dialogsch = false;
				} else {
					console.error(res.result.err);
					this.$dialog.message.error(res.result.err);
				}
			}
			catch(err) {
				console.error(err);
			}
			this.saveloading = false;
		},
		async deletesch() {
			this.saveloading = true;
			try {
				const {tag, queue, starttime, orderid} = this.curAppointment;
				const r = {tag, queue, starttime, orderid};
				const res = await this.$callCloudFunc({
					funcname: 'cancelAppointment',
					data: {...r, date: this.curdate, region:store.currentRegion}
				});
				if (res.result.ok) {
					const i = this.appointments[this.curdate].findIndex(x => x.tag === tag && x.queue === queue && x.starttime === starttime);
					this.appointments[this.curdate].splice(i, 1);
					this.makeDayView(this.curdate);
					this.$emit('aftercancel');
					this.dialogsch = false;
				} else if (res.result.err) {
					console.error(res.result.err);
					this.$dialog.message.error(res.result.err);
				} else {
					this.$dialog.message.error('出错了');
				}
			}
			catch(err) {
				console.error(err);
			}
			this.saveloading = false;
		},

		returnClick() {
			if (this.caltype !== 'month') {
				this.caltype = "month";
			}
		},
		prev () {
			this.$refs.calendar.prev();
			this.$nextTick(() => { this.curdate = this.$refs.calendar.value });
		},
		next () {
			this.$refs.calendar.next();
			this.$nextTick(() => { this.curdate = this.$refs.calendar.value });
		},
		updateRange({start, end}) {
			this.title = start.year + '年' + start.month + '月';
			this.start_ = start.date;
			this.end_ = end.date;
			this.fetchdata(start.date, end.date);
		},
		async progClick(e) {
			this.focus = e;
			this.curdate = e;
			await this.fetchDetails(e);
			this.caltype = 'day';
		},
		async fetchDetails(date) {
			this.loading = true;
			try {
				const res = await this.$callCloudFunc({
					funcname: 'getDayDetails',
					data: {
						date,
						region: store.currentRegion
					}
				});
				this.appointments[date] = res.result;
				this.makeDayView(date);
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
		async fetchdata(startdate, enddate) {
			this.loading = true;
			try {
				const res = await this.$callCloudFunc({
					funcname: 'getCalendar',
					data: {
						startdate,
						enddate,
						region: store.currentRegion
					}
				});
				this.plans = {};
				this.reserved = {};
				this.appointments = {};
				for(let d in res.result) {
					this.plans[d] = res.result[d].plan;
					this.reserved[d] = res.result[d].reserved;
					this.appointments[d] = res.result[d].appointments;
					this.makeView(d);
				}
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
		async fetchDoctors() {
			try {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const res = await db.collection('wp2doctor')
//					.where(_.and({region:_.elemMatch(_.eq(store.currentRegion))}, _.or({rank:_.gt(0)}, {duty:_.elemMatch(_.eq('bt'))})))
					.where(_.and({region:_.elemMatch(_.eq(store.currentRegion))}, _.or( {rank:_.gt(0)}, {duty:_.in(['bt','p','p2','z','c','jtz'])}, {appointed: true} )))
					.orderBy('rank','desc')
					.field({name:true, duty:true})
					.get();
				this.doctors = res.data;
			} catch(err) {
				console.error(err);
			}
		},
	}
}
</script>

<style>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}

#planrow .col {
	padding:6px;
}
</style>
