<template>
<div>
	<v-navigation-drawer app clipped v-model="drawer">
		<v-list shaped class="pb-8">
			<v-list-item-group color="primary" v-for="(mg,i) in activeMenus" :key="i">
				<v-list-item v-for="(m,j) in mg" :key="j" :to="m.to">
					<v-list-item-action class="ml-0">
						<v-icon class="material-icons-outlined">{{m.icon}}</v-icon>
					</v-list-item-action>
					<template v-if="m.badge">
						<v-badge color="red" bordered :value="!!m.badge()" :content="m.badge()">
							{{m.title}}
						</v-badge>
					</template>
					<template v-else>
						{{m.title}}
					</template>
				</v-list-item>
				<v-divider v-if="i < menus.length - 1"></v-divider>
			</v-list-item-group>
		</v-list>
	</v-navigation-drawer>
  	<v-main>
		<transition name="fade">
			<router-view/>
		</transition>
  	</v-main>
</div>
</template>

<script>
import store from './store.js'
export default {
	name: 'App',
	data() {
		return {
			drawer: true,
			menus: [[
					{title:'运营日历', 	   icon:'event',
					 to:"/"},
					{title:'客户进度',     icon:'event_note',
					 to:"/customer2",	  privileges:['全部客户总览','健康顾问']},
					/*{title:'我的任务',     icon:'view_module',
					 to:"/note2",          badge:this.countNotes}*/
				], [
					{title:'预约接待',     icon:'support_agent',
					 to:"/appointment2",   privileges:['预约接待','健康顾问']},
					{title:'付款管理',     icon:'payment',
					 to:"/order2",         privileges:['客户付款确认','健康顾问']},
					{title:'术前检查单',   icon:'fact_check',
					 to:"/questionnaire2",privileges:['术前检查单','消化内科','麻醉科','健康顾问']},
					{title:'术前评估',     icon:'how_to_reg',
					 to:"/anesEval2",     privileges:['术前评估','消化内科','麻醉科']},
					{title:'交付清肠药',   icon:'bento',
					 to:"/deliversup2",    privileges:['交付清肠药','健康顾问']},
					{title:'术前准备',     icon:'local_drink',
					 to:"/prepare2",       privileges:['健康顾问']},
					{title:'手术管理',     icon:'airline_seat_flat',
					 to:"/endoscopy2",     privileges:['手术管理']},
					{title:'术中结算',     icon:'receipt_long',
					 to:"/charge",        privileges:['术中结算','术中结算-只读','健康顾问']},
					{title:'术后管理',     icon:'phone',
					 to:"/feedback2",      privileges:['术后管理','健康顾问']},
					{title:'病理传递',     icon:'science',
					 to:"/pathologytransfer", privileges:['病理传递']},
					{title:'病理报告',     icon:'biotech',
					 to:"/pathology2",     privileges:['病理报告返回','病理报告解读','复诊开单','健康顾问']},
					{title:'复诊管理',     icon:'mdi-archive-refresh-outline',
					 to:"/subsequents",     privileges:['复诊开单','复诊管理','健康顾问']}
				], [
					{title:'门诊',         icon:'mdi-stethoscope',
					 to:"/clinic",        privileges:['门诊','门诊计费','健康顾问']},
					{title:'心电图',        icon:'monitor_heart',
					 to:"/ecg",           privileges:['术前检查单','心电图']},
				], [
					{title:'条码打印',     icon:'mdi-printer',
					 to:"/sticker",      privileges:['打印条码','术中结算','健康顾问']},
					{title:'客户信息',     icon:'dvr',
					 to:"/editinfo2",      privileges:['商务','健康顾问']},
					{title:'内镜报告查询', icon:'assignment',
					 to:"/report",        privileges:['内镜报告']},
					{title:'病历管理',    icon:'assignment',
					 to:"/medicalrecordman", privileges:['病历管理']},
					{title:'华三术前计费',  icon:'local_hospital',
					 to:"/preoper",       privileges:['华三术前']},
					{title:'华兆术前',      icon:'local_hospital',
					 to:"/huazhao",       privileges:['华兆','健康顾问']},
					{title:'其他收费',      icon:'receipt_long',
					 to:"/chargemisc",    privileges:['其他收费','商务','健康顾问']},
					{title:'保险预授权',    icon:'verified',
					 to:"/insurance",       privileges:['商务','保险预授权-只读','健康顾问']},
					{title:'视频查询',     icon:'video_library',//'query_stats',
					 to:"/videorec2",      privileges:['视频']},
					{title:'礼券',         icon:'redeem',
					 to:"/coupon",        privileges:['健康顾问']},
					{title:'礼券管理',      icon:'redeem',
					 to:"/redeem",        privileges:['礼券']},
				], [
					{title:'健康顾问管理', icon:'mdi-account-heart',
					 to:"/consultant",    privileges:['健康顾问管理']},
					{title:'医生管理',     icon:'mdi-doctor',
					 to:"/doctor2",       privileges:['医生管理']},
					{title:'用户管理',     icon:'supervisor_account',
					 to:"/userman",       privileges:['用户管理']},
					{title:'条目管理',     icon:'dvr',
					 to:"/itemman",       privileges:['条目管理']},
					{title:'手术统计',     icon:'bar_chart',
					 to:"/statoper",      privileges:['统计']},
					{title:'工作量统计',   icon:'bar_chart',
					 to:"/stat",          privileges:['统计']},
					{title:'预约统计',     icon:'bar_chart',
					 to:"/statapnt",      privileges:['统计']},
					{title:'日志',         icon:'receipt_long',
					 to:"/log2",           privileges:['查看日志']},
				]
			]
		}
	},
	mounted() {
		this.$root.$off('drawer');
		this.$root.$on('drawer', d => {
			this.drawer = d;
		});
	},
	methods: {
		/*
		countNotes() {
			return store.badge;
		}
		*/
	},
	computed: {
		activeMenus() {
			return this.menus.map(x => x.filter(y => !y.privileges || this.$hasPrivilege(y.privileges)));
		},
		/*
		notes() {
			return store.badge;
		}
		*/
	},
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .15s;
}
.fade-enter-active {
  transition-delay: .15s;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>