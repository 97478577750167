<template>
	<v-app>
		<v-app-bar app dense clipped-left dark color="#CE5925">
			<v-app-bar-nav-icon @click.stop="onClickDrawer"></v-app-bar-nav-icon>
			<v-toolbar-title>{{ AppTitle }}</v-toolbar-title>
			<v-spacer />
			<div style="width: 100px; margin: 0 16px">
				<v-select :items="regions" v-model="currentRegion" dense hide-details />
			</div>
			<v-menu open-on-hover offset-y :disabled="!account">
				<template v-slot:activator="{ on, attrs }">
					<v-btn text v-bind="attrs" v-on="on" style="text-transform: none">{{account}}</v-btn>
				</template>
				<v-list>
					<v-list-item @click="showDialog">
						<v-list-item-title>修改密码</v-list-item-title>
					</v-list-item>
					<v-list-item @click="onClickLogout">
						<v-list-item-title>退出</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<router-view />
		<v-dialog v-model="dialogPassword" persistent max-width="360">
			<v-card>
				<v-card-title>修改密码</v-card-title>
				<v-card-text>
					<v-form ref="passwordform">
						<v-text-field
							label="原密码"
							type="password"
							v-model.trim="oldpassword"
							:rules="textRules"
							:error-messages="oldpassworderr"
						/>
						<v-text-field
							label="新密码"
							type="password"
							v-model.trim="password0"
							:rules="passwordRules"
						/>
						<v-text-field
							label="重复新密码"
							type="password"
							v-model.trim="password1"
							:rules="passwordRules"
							:error-messages="password1err"
						/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						color="primary"
						@click.stop="save"
						:loading="loadingsave"
						:disabled="loadingsave"
						>确定</v-btn
					>
					<v-btn @click.stop="dialogPassword = false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
	import * as hash from 'object-hash'
	import store from "./store.js"

	export default {
		data: () => ({
			drawer: true,
			dialogPassword: false,
			loadingsave: false,
			oldpassword: "",
			password0: "",
			password1: "",
			textRules: [(v) => !!v || "此项为必填"],
			passwordRules: [v => !!v || "此项为必填", v => (/^[0-9a-zA-Z]*$/g).test(v) || '只允许字母和数字', v => v.length >= 4 || '至少4个字符'],
			oldpassworderr: "",
			password1err: "",
			AppTitle: "",
		}),
		mounted() {
			this.AppTitle = this.$AppTitle();
		},
		computed: {
			account() {
				return store.account;
			},
			regions() {
				return store.regions;
			},
			currentRegion: {
				get() {
					return store.currentRegion;
				},
				set(v) {
					store.currentRegion = v;
					this.$callCloudFunc({
						funcname: "changeRegion",
						data: {
							region: v,
						},
					});
				},
			},
		},
		methods: {
			onClickDrawer() {
				this.drawer = !this.drawer;
				this.$root.$emit("drawer", this.drawer);
			},
			onClickLogout() {
				this.$logout().then(() => {
					store.account = "";
					store.regions = [];
					store.currentRegion = "";
					this.$router.push("/login");
				});
			},
			showDialog() {
				this.oldpassword = "";
				this.password0 = "";
				this.password1 = "";
				this.oldpassworderr = "";
				this.password1err = "";
				this.dialogPassword = true;
				this.$nextTick(() => {
					this.$refs.passwordform.resetValidation();
				});
			},
			async save() {
				if (!this.$refs.passwordform.validate()) return;
				if (this.password0 !== this.password1) {
					this.password1err = "密码不一致";
					return;
				}
//				const oldpwd = hash(store.account + this.oldpassword);
//				const newpwd = hash(store.account + this.password0);
				const oldpwd = this.oldpassword;
				const newpwd = this.password0;
				this.loadingsave = true;
				try {
					const res = await this.$callCloudFunc({
						funcname: "changePassword",
						data: {
							oldpwd,
							newpwd,
						},
					});
					if (res.result?.err) {
						this.oldpassworderr = res.result.err;
					} else {
						this.dialogPassword = false;
						this.$dialog.message.success('密码修改成功');
					}
				} catch (err) {
					console.error(err);
				}
				this.loadingsave = false;
			},
		},
	};
</script>
