import 'core-js/actual/array/group-by'
import Vue from 'vue'
import App from './Empty.vue'
import Router from './router'
import store from './store.js'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import cloudbase from "@cloudbase/js-sdk";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import './global.css'
import * as hash from 'object-hash'

/*
Array.prototype.intersect = function(a) {
    return this.filter(value => a.includes(value));
}
*/
export function main(env, v) {

Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(VuetifyDialog, {
	context: {
		vuetify
	},
	confirm: {
		actions: {
			false: {text:'取消', color:'primary'},
			true: {text:'确定', outlined:true}
		}
	}
})

const app = cloudbase.init({
	env
});

Vue.prototype.$tcbapp = app;
Vue.prototype.$callCloudFunc = async(data) => app.callFunction({name: v === 2 ? "wp3cf" : 'wp2mp', data});

const auth = app.auth({ persistence: "session" });
Vue.prototype.$auth = auth;
const router = Router(v);
router.$auth = auth;

Vue.prototype.$AppTitle = () => router.AppTitle;

Vue.prototype.$getEdReports = async (q) => {
    try {
        const response = await axios.get('https://report.huasanclinic.com:8362/api/query',{params:q});
        if (response.data.info.length === 0) {
            return [];
        } else {
            response.data.info.forEach(x => {
				x.date = new Date(`${x.ScheduleDate}T00:00:00+08:00`);
				x.time = new Date(`${x.ScheduleDate}T${x.StudyTime}+08:00`);
				if (!x.imagesrc) {
                    response.data.files[1].forEach(y => {
                    	if (y.includes(x.AccessionNo)) x.imagesrc = 'https://report.huasanclinic.com:8362/files/2/' + y;
                    })
                }
            });
            response.data.info.sort((a,b)=>a.time-b.time).sort((a,b)=>b.date-a.date);	//首先本次手术在前；其次胃镜在前（按时间在前判断）
            return response.data.info;
        }
    } catch(err) {
        console.error(err);
    }
    return [];
}

/*
let nowWatcher;
setInterval(() => { store.nowOffsetHours += 0 }, 500);
const watchNow = async() => {
    try {
        if (nowWatcher) {
            await nowWatcher.close();
            nowWatcher = undefined;
        }
        const db = app.database();
        nowWatcher = db.collection('config').doc('current').watch({
            onChange: snapshot => {
                if (snapshot.docs.length > 0) {
                    const oh = snapshot.docs[0].nowOffsetHours;
                    setNowOffsetHours(oh);
                }
            },
            onError: err => {
                console.error(err);
            }
        })
    } catch (err) {
        console.error(err);
    }
}

const unwatchNow = async() => {
    try {
        if (nowWatcher) {
            await nowWatcher.close();
            nowWatcher = undefined;
        }
    } catch (err) {
        console.error(err);
    }
}
*/
Vue.prototype.$login = async(username, password) => {
    if (v === 2) password = hash(username + password);
    const fn = v === 2 ? 'wp3ticket' : 'get_ticket';
    try {
        const res = await app.callFunction({ name: fn, data: { username, password, v } });
        if (res.result.ret === 0) {
            await auth.customAuthProvider().signIn(res.result.ticket);
            if (v === 2) {
                const {userAgent, userAgentData} = navigator;
                await Vue.prototype.$callCloudFunc({funcname:'webLogin', data:{userAgent, userAgentData: userAgentData ? JSON.stringify(userAgentData) : null}});
            } else {
                await app.callFunction({ name: "writeLog", data: { title: '用户登录' } });
            }
            store.currentUser = res.result.user;
			store.regions = store.currentUser.region;
			store.currentRegion = store.currentUser.regionDefault || '北京丰联';
			store.currentUser.regionDefault = store.currentRegion;
            window.sessionStorage.setItem('user', JSON.stringify(store.currentUser));
            app.database().collection('workset').doc('current').get().then(res => {
                window.sessionStorage.setItem('workset', JSON.stringify(res.data[0]));
            });
//            await watchNow();
            return { success: true }
        } else {
            return { success: false, errmsg: res.result.msg }
        }
    } catch (err) {
        console.error(err);
        return { success: false, errmsg: err }
    }
}

Vue.prototype.$logout = async() => {
    try {
//        await unwatchNow();
        await app.callFunction({ name: "writeLog", data: { title: '用户登出' } });
        await auth.signOut();
        store.currentUser = null;
        window.sessionStorage.removeItem('user');
    } catch (err) {
        console.error(err);
    }
}

Vue.prototype.$getCurrentUser = () => {
    if (store.currentUser) return store.currentUser;
    const us = window.sessionStorage.getItem('user');
    if (!us) return null;
    return JSON.parse(us);
}

Vue.prototype.$hasPrivilege = (p) => {
    let user;
    if (store.currentUser) {
        user = store.currentUser;
    } else {
        const us = window.sessionStorage.getItem('user');
        if (!us) return false;
        user = JSON.parse(us);
    }
    if (user.role.includes('admin')) return true;
    const pt = Object.prototype.toString.call(p);
    if (pt === '[object Array]') {
        return p.some(x => user.role.includes(x));
    } else if (pt === '[object String]') {
        return user.role.includes(p);
    }
    return false;
}
/*
let notesWatcher;
Vue.prototype.$watchNote = async(filter) => {
    try {
        if (notesWatcher) {
            await notesWatcher.close();
            notesWatcher = undefined;
        }
        const db = app.database();
        const cname = ['note', 'wp2note'][v];
        notesWatcher = db.collection(cname).where(filter).watch({
            onChange: snapshot => {
                store.badge = snapshot.docs.length;
            },
            onError: err => {
                console.error(err);
            }
        })
    } catch (err) {
        console.error(err);
    }
}

Vue.prototype.$unwatchNote = async() => {
    try {
        if (notesWatcher) {
            await notesWatcher.close();
            notesWatcher = undefined;
        }
    } catch (err) {
        console.error(err);
    }
}
*/
Vue.use(Viewer)
Viewer.setDefaults({
    transition: false,
    focus: false,
    navbar: false,
    toolbar: {
        zoomIn: 'large',
        zoomOut: 'large',
        oneToOne: 'large',
        reset: 'large',
        prev: 'large',
        next: 'large',
        rotateLeft: 'large',
        rotateRight: 'large',
        flipHorizontal: 'large',
        flipVertical: 'large'
    }
});

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')

}
