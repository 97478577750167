import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store.js'

Vue.use(VueRouter)

import routes2 from './r2'
import routes3 from './r3'
const routes = [null, routes2, routes3];
const AppTitles = ['', '华三WP', 'HeronNexus 内镜中心信息系统'];

function Router(v) {

	const router = new VueRouter({
		routes: routes[v]
	})

	router.beforeEach((to, from, next) => {
		if (to.path === '/login') {
			next();
		} else {
			const isLogin = router.$auth.hasLoginState();
			if (isLogin) {
				if (!store.account) {
					store.account = router.$auth.currentUser.customUserId;
					const us = window.sessionStorage.getItem('user');
					if (us) {
						store.currentUser = JSON.parse(us);
						store.regions = store.currentUser.region;
						store.currentRegion = store.currentUser.regionDefault;
					}
				}
				next();
			} else {
				next({ path: '/login', query: { redirect: to.fullPath } });
			}
		}
	})

	router.AppTitle = AppTitles[v];

	return router
}

export default Router