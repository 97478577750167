<template>
	<div style="display:flex;height:100%;">
		<v-navigation-drawer app clipped v-model="drawer">
			<v-list shaped dense class="pb-8">
				<v-list-item-group color="primary" v-for="(mg,i) in activeMenus" :key="i">
					<v-list-item v-for="(m,j) in mg" :key="j" :to="m.to">
						<v-list-item-icon>
							<v-icon v-text="m.icon"/>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="m.title"/>
						</v-list-item-content>
					</v-list-item>
					<v-divider v-if="i < menus.length - 1"/>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-main>
			<transition name="fade">
				<router-view/>
			</transition>
		</v-main>
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			drawer: true,
			menus: [[
					{title:'运营日历', 	   icon:'event',
					 to:"/"},
					{title:'预约',        icon:'support_agent',
					 to:"/appointment",   privileges:['预约','代理人']},
					{title:'镜检进度',     icon:'dvr',
					 to:"/order",	      privileges:['镜检进度','代理人']},
					{title:'付款管理',     icon:'payment',
					 to:"/payment",        privileges:['付款管理','代理人']},
				], [
					{title:'检前评估',    icon:'checklist',
					 to:"/preexam",       privileges:['检前评估']},
					{title:'内镜检查',     icon:'airline_seat_flat',
					 to:"/endoscopy",     privileges:['内镜检查']},
					{title:'检中结算',     icon:'receipt_long',
					 to:"/charge",        privileges:['检中结算']},
					{title:'病理解读',     icon:'biotech',
					 to:"/pathology",     privileges:['病理解读']},
				], [
					{title:'门诊',         icon:'mdi-stethoscope',
					 to:"/clinic",        privileges:['门诊']},
					{title:'报告中心', 	   icon:'mdi-text-box-multiple-outline',
					 to:"/report",        privileges:['报告中心']},
				], [
					{title:'用户管理',     icon:'mdi-account-multiple-outline',
					 to:"/userman",       privileges:['用户管理']},
					{title:'医生管理',     icon:'mdi-doctor',
					 to:"/doctor",        privileges:['医生管理']},
					{title:'医嘱条目管理', icon:'list_alt',
					 to:"/itemman",       privileges:['条目管理']},
					{title:'检中计费模板', icon:'list_alt',
					 to:"/chargetmpl",     privileges:['费用模板']},
					{title:'业务设置',     icon:'list_alt',
					 to:"/config",        privileges:['业务设置']},
					{title:'日志',         icon:'mdi-clipboard-text-clock',
					 to:"/log",           privileges:['日志']},
				]
			]
		}
	},
	mounted() {
		this.$root.$off('drawer');
		this.$root.$on('drawer', d => {
			this.drawer = d;
		});
	},
	methods: {
	},
	computed: {
		activeMenus() {
			return this.menus.map(x => x.filter(y => !y.privileges || this.$hasPrivilege(y.privileges)));
		},
	},
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .15s;
}
.fade-enter-active {
  transition-delay: .15s;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>