export const colorsptz = [
    {tag: 't', color:'#FF6262',text:   'ZT',  durations:[45,30,60], global:false},
    {tag:'bt', color:'#CE92FE',text:   'BT',  durations:[45,30,60], global:false, hideDoctor:true},
    {tag: 'p', color:'#42A5F5',text: '视评',  durations:[10,15,30,40,60], global:true},
    {tag:'p2', color:'#FFB300',text: '院评',  durations:[10,15,30], global:false, hideDoctor:true, comments:['[内]','[内+麻]']},
    {tag: 'z', color:'#90D090',text: '解读',  durations:[10,15,30], global:true},
    {tag:'jtz',color:'#7986CB',text: '活动',  durations:[45,30,60], global:false},
    {tag: 'c', color:'#9E9D24',text: '门诊',  durations:[10,15,30,45,60], global:false},
    {tag:'bld',color:'#AD3477',text: '采血',  durations:[10,15], global:false},
]

export const duties = colorsptz.map(x => ({text:x.text, value:x.tag})).concat([{text:'麻醉', value:'mz'}]);
